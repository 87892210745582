import React, { PureComponent } from "react";
import { PhoneMask } from "../../Images";
import { FAQ_CARD } from "../../Components";
import "./FAQ.scss";

class FAQ extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div id="faq" className="faq-container">
        <div className="left-side-wrapper">
          <div className="faq-header-wrapper">
            <div className="faq-header">FREQUENTLY ASKED QUESTIONS</div>
            <div className="faq-break" />
            <div className="faq-text">Create a virtual fitness event with no overhead; or extend a physical event virtually to reach more of your supporters.</div>
          </div>
          <div className="faq-cards-wrapper">
            <FAQ_CARD question={"Q: Is there a limit to what I can raise money for?"} answer={"Ans. No. With Moolathon you can raise money for any personal or philanthropic cause. Simply share your motivation to get fit and raise money on your profile, and share to gain sponsorships. If you’d like to raise money as a group or organization, simply create a virtual fitness fundraiser for your cause."} />
            <FAQ_CARD question={"Q: Where does the money I raise go?"} answer={"Ans. The funds you’ve raised will be sent to the connected account of the sponsored individual, or group/organization. If you are raising money as a group/organization, we suggest that the person in charge of accounting for your group/organization be in charge of this."} />
            <FAQ_CARD question={"Q: How long will it take to receive the funds I’ve raised?"} answer={"Ans. Funds raised through event registrations will be paid out 7-10 business days after a registration fee is received. Funds raised through sponsorships will be received 7-10 business days after you have completed all the miles a sponsor has pledged."} />
            <FAQ_CARD question={"Q: Is Moolathon available on Android and Apple Watch?"} answer={"Ans. SOON! Currently Moolathon is only available in the iOS App Store, but will be available on the Apple Watch early 2022, and on Android mid 2022. You need the app to participate in fitness fundraiser events, and to raise money through sponsorships, but your supporters do not need the app to sponsor you, or support your event. Sponsors can support individuals through our web platform, and groups/organizations can receive donations through our web platform as well."} />
            <FAQ_CARD question={"Q: Can Moolathon help us with our in-person fitness fundraisers as well?"} answer={"Ans. Yes. Moolathon can be used as a virtual only fundraiser with little to no overhead, or it can be used to virtually  extend an in-person fundraiser to reach more people in more places."} />
          </div>
        </div>
        <div className="right-side-wrapper">
          <div className="right-side-image-wrapper">
            <div>
              <img src={PhoneMask} alt="PhoneMask.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
