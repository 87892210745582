import React, { PureComponent } from "react";
import { Fitness, Sponsorship, LogoTitle } from "../../Images";
import "./Moolathon.scss";

class Index extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  scrollTo(name) {
    const element = document.getElementById(name);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  render() {
    return (
      <div className="moolathon-container" style={{ position: "relative" }}>
        <div id="features" className="features" style={{ position: "absolute", top: -71, right: "50%" }} />
        <div className="header-wrapper">
          <div className="header"><img src={LogoTitle} alt="Moolathon"/></div>
          <div className="break" />
          <div className="header-text">Raise money as a group with events, or take matters into your own hands and raise money through sponsorships.</div>
        </div>
        <div className="features-wrapper">
          <div className="wrap_width">
            <div className="feature">
              <div className="image-wrapper">
                <div>
                  <img src={Fitness} alt="FollowOthersIcon.png" />
                </div>
              </div>
              <div className="feature-header margin">VIRTUAL FITNESS FUNDRAISERS</div>
              <div className="feature-text">helps expand the fundraising of any group or organization by giving your supporters the ability to participate in your event anytime, anywhere.</div>
              <div className="button-wrapper">
                <div className="moolathon-button" onClick={() => this.scrollTo("events")}>
                  FIND OUT MORE
                </div>
              </div>
            </div>
            <div className="feature">
              <div className="image-wrapper">
                <div>
                  <img src={Sponsorship} alt="MoolastatsIcon.png" />
                </div>
              </div>
              <div className="feature-header margin">SPONSORSHIPS</div>
              <div className="feature-text">raise money for any personal or philanthropic cause with the miles you run, walk, or bike.</div>

              <div className="button-wrapper">
                <div className="moolathon-button" onClick={() => this.scrollTo("sponsorships")}>
                  FIND OUT MORE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Index;
