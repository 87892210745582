import React, { PureComponent } from 'react'
import { img8, img9, img10 } from "../../Images";
import './Sponsorship.scss'

class Sponsorship extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
          <div className="sponsorships-container" style={{ position: "relative" }}>
            <div id="sponsorships" style={{ position: "absolute", top: -71, right: "50%" }} />
            <div className="sponsorship-header-wrapper">
              <div className="sponsorship-header">Sponsorships</div>
              <div className="sponsorship-divider" />
              <div className="sponsorship-text">Raise money with the miles you walk, run and bike.</div>
            </div>
            <div className="sponsorship-steps-wrapper">
              <div className="sponsorship-steps">
                <div className="phone-image">
                  <div>
                    <img src={img8} alt="iPhone.png" />
                  </div>
                </div>
                <div className="sponsorship-step-number">1</div>
                <div className="sponsorship-step-title">Create your profile</div>
                <div className="sponsorship-step-text">Share your motivation to get fit + raise money via social media, text and email.</div>
              </div>
              <div className="sponsorship-steps">
                <div className="phone-image">
                  <div>
                    <img src={img9} alt="iPhone.png" />
                  </div>
                </div>
                <div className="sponsorship-step-number">2</div>
                <div className="sponsorship-step-title">Get sponsored </div>
                <div className="sponsorship-step-text">Sponsors pledge a dollar amount for every mile you log, and set a milestone for your payout!</div>
              </div>
              <div className="sponsorship-steps">
                <div className="phone-image">
                  <div>
                    <img src={img10} alt="iPhone.png" />
                  </div>
                </div>
                <div className="sponsorship-step-number">3</div>
                <div className="sponsorship-step-title">Get fit + raise money</div>
                <div className="sponsorship-step-text">Every mile logged earns more of each sponsor pledge.</div>
              </div>
            </div>
            <div className="sponsorship-bottom-text">Once you complete all the miles a sponsor has pledged, they are charged and the money sent to your linked account.</div>
          </div>
        );
    }
}

export default Sponsorship