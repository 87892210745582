import React, { PureComponent } from "react";
import { BeSocialIcon, FollowOthersIcon, MoolastatsIcon, Leaderboard } from "../../Images";
import "./Leadership.scss";

class Leadership extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  scrollTo(name) {
    const element = document.getElementById(name);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  render() {
    return (
      <div className="moolathon-container leadership" style={{ position: "relative" }}>
        <div id="features" className="features" style={{ position: "absolute", top: -71, right: "50%" }} />
        <div className="features-wrapper">
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={Leaderboard} alt="ProgressIcon.png" style={{ height: "70px", objectFit: "cover" }} />
              </div>
            </div>
            <div className="feature-header">Leaderboards</div>
            <div className="feature-text">Make it fun and engaging for your supporters to participate in your event.</div>
          </div>
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={BeSocialIcon} alt="BeSocialIcon.png" />
              </div>
            </div>
            <div className="feature-header">Social Engagement</div>
            <div className="feature-text">When registering or completing your event, participants in your event can share this accomplishment to spread awareness for your cause.</div>
          </div>
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={FollowOthersIcon} alt="FollowOthersIcon.png" />
              </div>
            </div>
            <div className="feature-header">easy + fun</div>
            <div className="feature-text">Moolathon makes it easy to organize your event and collect payments, while making participation fun and engaging for your supporters.</div>
          </div>
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={MoolastatsIcon} alt="MoolastatsIcon.png" />
              </div>
            </div>
            <div className="feature-header">Quick Payout </div>
            <div className="feature-text">Receive funds 7-10 business days after collecting registration fees.</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Leadership;
