import React, { PureComponent } from "react";
import { Events1, Events2, Events3 } from "../../Images";
import "./Events.scss";

class Events extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="events-container" style={{ position: "relative" }}>
        <div id="events" className="features" style={{ position: "absolute", top: -71, right: "50%" }} />
        <div className="events-header-wrapper">
          <div className="events-header">Fitness Fundraising</div>
          <div className="events-divider" />
          <div className="events-text">Create a virtual fitness event with no overhead, or extend a physical event virtually to reach more of your supporters.</div>
        </div>
        <div className="event-steps-wrapper">
          <div className="event-steps">
            <div className="phone-image">
              <div>
                <img src={Events1} alt="iPhone.png" />
              </div>
            </div>
            <div className="event-step-number">1</div>
            <div className="event-step-title">CREATE YOUR EVENT</div>
            <div className="event-step-text">Set the distance, description and minimum entry fee for your event. Then share via social media, text and email.</div>
          </div>
          <div className="event-steps">
            <div className="phone-image">
              <div>
                <img src={Events2} alt="iPhone.png" />
              </div>
            </div>
            <div className="event-step-number">2</div>
            <div className="event-step-title">Supporters register</div>
            <div className="event-step-text">Participants pay the minimum entry fee (or more) to register for your event. </div>
          </div>
          <div className="event-steps">
            <div className="phone-image">
              <div>
                <img src={Events3} alt="iPhone.png" />
              </div>
            </div>
            <div className="event-step-number">3</div>
            <div className="event-step-title">PARTICIPATE</div>
            <div className="event-step-text">Once registered, your supporters use the Moolathon app to participate anytime, anywhere.</div>
          </div>
        </div>
        <div className="event-bottom-text">Moolathon makes it easy to create engaging and effective fundraisers for your group, organization, school or team.</div>
      </div>
    );
  }
}

export default Events;
