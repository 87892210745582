import React, { PureComponent } from "react";
import { ProgressIcon, BeSocialIcon, FollowOthersIcon, MoolastatsIcon } from "../../Images";
import "./Progress.scss";

class Progress extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  scrollTo(name) {
    const element = document.getElementById(name);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  render() {
    return (
      <div className="moolathon-container progress" style={{ position: "relative" }}>
        <div id="features" className="features" style={{ position: "absolute", top: -71, right: "50%" }} />
        <div className="features-wrapper">
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={ProgressIcon} alt="ProgressIcon.png" />
              </div>
            </div>
            <div className="feature-header">PROGRESS REPORTS</div>
            <div className="feature-text">Your sponsors receive progress reports after each of your workouts telling them how much of their pledge you have earned to date.</div>
          </div>
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={BeSocialIcon} alt="BeSocialIcon.png" />
              </div>
            </div>
            <div className="feature-header">SHARE</div>
            <div className="feature-text">As you log miles and raise more money, you can share this accomplishment with the world.</div>
          </div>
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={FollowOthersIcon} alt="FollowOthersIcon.png" />
              </div>
            </div>
            <div className="feature-header">FOLLOW</div>
            <div className="feature-text">Follow friends and organizations to see how they are using Moolathon to get fit and raise money.</div>
          </div>
          <div className="feature">
            <div className="image-wrapper">
              <div>
                <img src={MoolastatsIcon} alt="MoolastatsIcon.png" />
              </div>
            </div>
            <div className="feature-header">Moola-metrics</div>
            <div className="feature-text">Your profile gives you data to quantify your physical fitness, and measure your fundraising impact.</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Progress;
